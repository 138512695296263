import styles from "./styles/NarrowPage.module.css";

export default function NarrowPage({ title = null, icon = null, children }) {
  return (
    <div className={styles.cont}>
      <div className={styles.contInner}>
        <div className={styles.headerCont}>
          <div className={styles.header}>
            <span className={styles.icon}>{icon}</span> {title}
          </div>
        </div>
        <div
          className={styles.bodyCont + " border border-b-[3px] md:rounded-lg"}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
